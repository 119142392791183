import { useQuery } from '@tanstack/react-query'
import { ServiceApiFactory } from 'generated/restapi'
import { useApiClient } from 'services/use-api-client'
import { emitError } from 'common/utils/event-emitter'

export type TGetServiceInfoVariables = { orgId?: number; srvId?: number }

const serviceInfoKeys = {
  'serviceInfo': (params?: TGetServiceInfoVariables) => ['fetch-service-info', { ...params }],
}

export const useServiceServiceInfo = () => {
  const { restConfig, isReady } = useApiClient()

  const FetchServiceInfo = (params: TGetServiceInfoVariables) => {
    const { getServiceInfo } = ServiceApiFactory(restConfig)
    const { orgId, srvId } = params

    const queryKey = serviceInfoKeys.serviceInfo(params)
    const queryFn = () => getServiceInfo(orgId!, srvId!)

    return useQuery(queryKey, queryFn, {
      enabled: isReady && !!orgId && !!srvId,
      staleTime: 1000 * 60 * 60,
      select: (data) => data.data,
      onError: () => {
        emitError(`Error fetching service info.`)
      },
    })
  }

  return { FetchServiceInfo }
}
