import Typography, { TypographyProps } from '@mui/material/Typography'
import { useAppContextServiceInfo } from 'common/hooks/use-app-context-service-info'

const CentreName = (props: TypographyProps) => {
  const { serviceInfo } = useAppContextServiceInfo()

  return (
    <Typography
      variant='h4'
      sx={{ display: 'flex', alignItems: 'center', '@media print': { display: 'none' } }}
      {...props}
    >
      {serviceInfo?.name.toLocaleUpperCase()}
    </Typography>
  )
}

export default CentreName
