import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { Job, Organisation, RoomInfo, ServiceInfo, UserInfo } from 'generated/restapi'
import { useServiceJobs } from 'services/use-service-jobs'
import { useServiceServiceInfo } from 'services/use-service-service-info'
import { fallbackOpDays, getOpDays } from 'common/utils/operating-days-utils'
import { useServiceOrganisationInfo } from 'services/use-service-organisation-info'
import { useServiceUserInfo } from 'services/use-service-user-info'

interface IAppContextProps {
  userJobCtx: {
    userId: number | undefined
    userJob: Job | undefined
  }
  userInfoCtx: {
    userInfo: UserInfo | undefined
  }
  roomCtx: {
    rooms: RoomInfo[]
    selectedRoom: RoomInfo | undefined
    setSelectedRoom: Dispatch<SetStateAction<RoomInfo | undefined>>
  }
  serviceInfoCtx: {
    serviceInfo: ServiceInfo | undefined
    isServiceInfoLoading: boolean
    operatingDays: string[]
  }
  orgInfoCtx: {
    organisationInfo: Organisation | undefined
    isOrgInfoLoading: boolean
    logoSrc: string | undefined
  }
}

const AppContext = createContext<IAppContextProps>({
  userJobCtx: { userId: undefined, userJob: undefined },
  userInfoCtx: { userInfo: undefined },
  roomCtx: { rooms: [], selectedRoom: undefined, setSelectedRoom: () => {} },
  serviceInfoCtx: { serviceInfo: undefined, isServiceInfoLoading: true, operatingDays: fallbackOpDays },
  orgInfoCtx: { organisationInfo: undefined, isOrgInfoLoading: true, logoSrc: undefined },
})

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const { srvid } = useRouter().query
  const { data: dataJobInfo } = useServiceJobs().FetchJobs()
  const { data: dataUserInfo } = useServiceUserInfo().FetchUserInfo()

  //#region - User job context
  const userId = useMemo(() => {
    return dataJobInfo?.user_id
  }, [dataJobInfo])

  const userJob = useMemo(() => {
    return dataJobInfo?.jobs.find(({ service_id }) => service_id.toString() === srvid)
  }, [srvid, dataJobInfo])
  //#endregion

  // #region - User info context
  const userInfo = useMemo(() => {
    return dataUserInfo
  }, [dataUserInfo])
  // #endregion

  //#region - Rooms context
  const [selectedRoom, setSelectedRoom] = useState<RoomInfo>()
  const rooms = useMemo(() => {
    let rooms =
      dataJobInfo?.rooms
        ?.filter((room) => room.service_id.toString() === srvid && !room.inactive && room.room_type === 'contact')
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })) || []
    return rooms
  }, [srvid, dataJobInfo?.rooms])
  //#endregion

  //#region - Service Info context
  const { data: serviceInfo, isLoading: isServiceInfoLoading } = useServiceServiceInfo().FetchServiceInfo({
    orgId: userJob?.org_id,
    srvId: userJob?.service_id,
  })
  const operatingDays = useMemo(() => getOpDays(serviceInfo), [serviceInfo])
  //#endregion

  //#region - Organisation Info context
  const { data: organisationInfo, isLoading: isOrgInfoLoading } = useServiceOrganisationInfo().FetchOrgInfo({
    orgId: userJob?.org_id,
  })
  const logoSrc = useMemo(() => {
    if (organisationInfo?.logo_data) {
      const logoId = JSON.parse(organisationInfo.logo_data)
      return process.env.NEXT_PUBLIC_BASE_URL + '/uploads/store/' + logoId.id
    }
    return undefined
  }, [organisationInfo])
  //#endregion

  // const value = useMemo(() => ({ roomCtx: { rooms, selectedRoom, setSelectedRoom } }), [rooms, selectedRoom])
  const value = {
    userJobCtx: { userId, userJob },
    userInfoCtx: { userInfo },
    roomCtx: { rooms, selectedRoom, setSelectedRoom },
    serviceInfoCtx: { serviceInfo, isServiceInfoLoading, operatingDays },
    orgInfoCtx: { organisationInfo, isOrgInfoLoading, logoSrc },
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
