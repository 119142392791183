/* tslint:disable */
/* eslint-disable */
/**
 * New Kindyhub
 * New Kindyhub API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMessageRequest } from '../models';
// @ts-ignore
import { CreateMessageStaffRequest } from '../models';
// @ts-ignore
import { Message } from '../models';
// @ts-ignore
import { MessageInfo } from '../models';
// @ts-ignore
import { MessageInfoStaff } from '../models';
// @ts-ignore
import { MessageMetadataInfo } from '../models';
// @ts-ignore
import { MessageMetadataInfoStaff } from '../models';
// @ts-ignore
import { MessagesStaff } from '../models';
// @ts-ignore
import { ReadMessageRequest } from '../models';
// @ts-ignore
import { ReadMessageStaffRequest } from '../models';
/**
 * CommunicationApi - axios parameter creator
 * @export
 */
export const CommunicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a message for a service
         * @param {number} srvId Service ID
         * @param {CreateMessageRequest} createMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage: async (srvId: number, createMessageRequest: CreateMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('createMessage', 'srvId', srvId)
            // verify required parameter 'createMessageRequest' is not null or undefined
            assertParamExists('createMessage', 'createMessageRequest', createMessageRequest)
            const localVarPath = `/srv/{srv_id}/create_message`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a message for a service for staff_messages
         * @param {number} srvId Service ID
         * @param {CreateMessageStaffRequest} createMessageStaffRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageStaff: async (srvId: number, createMessageStaffRequest: CreateMessageStaffRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('createMessageStaff', 'srvId', srvId)
            // verify required parameter 'createMessageStaffRequest' is not null or undefined
            assertParamExists('createMessageStaff', 'createMessageStaffRequest', createMessageStaffRequest)
            const localVarPath = `/srv/{srv_id}/create_message_staff`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageStaffRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get service messages
         * @param {number} srvId Service ID
         * @param {string} dateFrom Start date for messages retrieval
         * @param {string} dateTo End date for messages retrieval
         * @param {string} childId child id string
         * @param {string} userEmail userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (srvId: number, dateFrom: string, dateTo: string, childId: string, userEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getMessages', 'srvId', srvId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getMessages', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getMessages', 'dateTo', dateTo)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getMessages', 'childId', childId)
            // verify required parameter 'userEmail' is not null or undefined
            assertParamExists('getMessages', 'userEmail', userEmail)
            const localVarPath = `/srv/{srv_id}/get_message`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }

            if (childId !== undefined) {
                localVarQueryParameter['childId'] = childId;
            }

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get service messages metadata
         * @param {number} srvId Service ID
         * @param {string} userEmail user Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesMetadata: async (srvId: number, userEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getMessagesMetadata', 'srvId', srvId)
            // verify required parameter 'userEmail' is not null or undefined
            assertParamExists('getMessagesMetadata', 'userEmail', userEmail)
            const localVarPath = `/srv/{srv_id}/get_message_metadata`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get service messages metadata for staff_messages
         * @param {number} srvId Service ID
         * @param {string} userId user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesMetadataStaff: async (srvId: number, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getMessagesMetadataStaff', 'srvId', srvId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMessagesMetadataStaff', 'userId', userId)
            const localVarPath = `/srv/{srv_id}/get_message_metadata_staff`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get service messages for staff_messages
         * @param {number} srvId Service ID
         * @param {string} dateFrom Start date for messages retrieval
         * @param {string} dateTo End date for messages retrieval
         * @param {string} userId user id
         * @param {string} [topic] message chatroom or topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesStaff: async (srvId: number, dateFrom: string, dateTo: string, userId: string, topic?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getMessagesStaff', 'srvId', srvId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getMessagesStaff', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getMessagesStaff', 'dateTo', dateTo)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMessagesStaff', 'userId', userId)
            const localVarPath = `/srv/{srv_id}/get_message_staff`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets read message status
         * @param {number} srvId Service ID
         * @param {ReadMessageRequest} readMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMessage: async (srvId: number, readMessageRequest: ReadMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('readMessage', 'srvId', srvId)
            // verify required parameter 'readMessageRequest' is not null or undefined
            assertParamExists('readMessage', 'readMessageRequest', readMessageRequest)
            const localVarPath = `/srv/{srv_id}/read_message`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets read message status for staff_messages
         * @param {number} srvId Service ID
         * @param {ReadMessageStaffRequest} readMessageStaffRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMessageStaff: async (srvId: number, readMessageStaffRequest: ReadMessageStaffRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('readMessageStaff', 'srvId', srvId)
            // verify required parameter 'readMessageStaffRequest' is not null or undefined
            assertParamExists('readMessageStaff', 'readMessageStaffRequest', readMessageStaffRequest)
            const localVarPath = `/srv/{srv_id}/read_message_staff`
                .replace(`{${"srv_id"}}`, encodeURIComponent(String(srvId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readMessageStaffRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunicationApi - functional programming interface
 * @export
 */
export const CommunicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a message for a service
         * @param {number} srvId Service ID
         * @param {CreateMessageRequest} createMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessage(srvId: number, createMessageRequest: CreateMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(srvId, createMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a message for a service for staff_messages
         * @param {number} srvId Service ID
         * @param {CreateMessageStaffRequest} createMessageStaffRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessageStaff(srvId: number, createMessageStaffRequest: CreateMessageStaffRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagesStaff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessageStaff(srvId, createMessageStaffRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get service messages
         * @param {number} srvId Service ID
         * @param {string} dateFrom Start date for messages retrieval
         * @param {string} dateTo End date for messages retrieval
         * @param {string} childId child id string
         * @param {string} userEmail userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(srvId: number, dateFrom: string, dateTo: string, childId: string, userEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(srvId, dateFrom, dateTo, childId, userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get service messages metadata
         * @param {number} srvId Service ID
         * @param {string} userEmail user Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesMetadata(srvId: number, userEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageMetadataInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesMetadata(srvId, userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get service messages metadata for staff_messages
         * @param {number} srvId Service ID
         * @param {string} userId user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesMetadataStaff(srvId: number, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageMetadataInfoStaff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesMetadataStaff(srvId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get service messages for staff_messages
         * @param {number} srvId Service ID
         * @param {string} dateFrom Start date for messages retrieval
         * @param {string} dateTo End date for messages retrieval
         * @param {string} userId user id
         * @param {string} [topic] message chatroom or topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesStaff(srvId: number, dateFrom: string, dateTo: string, userId: string, topic?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageInfoStaff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesStaff(srvId, dateFrom, dateTo, userId, topic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets read message status
         * @param {number} srvId Service ID
         * @param {ReadMessageRequest} readMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readMessage(srvId: number, readMessageRequest: ReadMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readMessage(srvId, readMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets read message status for staff_messages
         * @param {number} srvId Service ID
         * @param {ReadMessageStaffRequest} readMessageStaffRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readMessageStaff(srvId: number, readMessageStaffRequest: ReadMessageStaffRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagesStaff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readMessageStaff(srvId, readMessageStaffRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunicationApi - factory interface
 * @export
 */
export const CommunicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunicationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a message for a service
         * @param {number} srvId Service ID
         * @param {CreateMessageRequest} createMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(srvId: number, createMessageRequest: CreateMessageRequest, options?: any): AxiosPromise<Message> {
            return localVarFp.createMessage(srvId, createMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a message for a service for staff_messages
         * @param {number} srvId Service ID
         * @param {CreateMessageStaffRequest} createMessageStaffRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageStaff(srvId: number, createMessageStaffRequest: CreateMessageStaffRequest, options?: any): AxiosPromise<MessagesStaff> {
            return localVarFp.createMessageStaff(srvId, createMessageStaffRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get service messages
         * @param {number} srvId Service ID
         * @param {string} dateFrom Start date for messages retrieval
         * @param {string} dateTo End date for messages retrieval
         * @param {string} childId child id string
         * @param {string} userEmail userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(srvId: number, dateFrom: string, dateTo: string, childId: string, userEmail: string, options?: any): AxiosPromise<MessageInfo> {
            return localVarFp.getMessages(srvId, dateFrom, dateTo, childId, userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get service messages metadata
         * @param {number} srvId Service ID
         * @param {string} userEmail user Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesMetadata(srvId: number, userEmail: string, options?: any): AxiosPromise<MessageMetadataInfo> {
            return localVarFp.getMessagesMetadata(srvId, userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get service messages metadata for staff_messages
         * @param {number} srvId Service ID
         * @param {string} userId user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesMetadataStaff(srvId: number, userId: string, options?: any): AxiosPromise<MessageMetadataInfoStaff> {
            return localVarFp.getMessagesMetadataStaff(srvId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get service messages for staff_messages
         * @param {number} srvId Service ID
         * @param {string} dateFrom Start date for messages retrieval
         * @param {string} dateTo End date for messages retrieval
         * @param {string} userId user id
         * @param {string} [topic] message chatroom or topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesStaff(srvId: number, dateFrom: string, dateTo: string, userId: string, topic?: string, options?: any): AxiosPromise<MessageInfoStaff> {
            return localVarFp.getMessagesStaff(srvId, dateFrom, dateTo, userId, topic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets read message status
         * @param {number} srvId Service ID
         * @param {ReadMessageRequest} readMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMessage(srvId: number, readMessageRequest: ReadMessageRequest, options?: any): AxiosPromise<Message> {
            return localVarFp.readMessage(srvId, readMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets read message status for staff_messages
         * @param {number} srvId Service ID
         * @param {ReadMessageStaffRequest} readMessageStaffRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMessageStaff(srvId: number, readMessageStaffRequest: ReadMessageStaffRequest, options?: any): AxiosPromise<MessagesStaff> {
            return localVarFp.readMessageStaff(srvId, readMessageStaffRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunicationApi - object-oriented interface
 * @export
 * @class CommunicationApi
 * @extends {BaseAPI}
 */
export class CommunicationApi extends BaseAPI {
    /**
     * 
     * @summary Create a message for a service
     * @param {number} srvId Service ID
     * @param {CreateMessageRequest} createMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public createMessage(srvId: number, createMessageRequest: CreateMessageRequest, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).createMessage(srvId, createMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a message for a service for staff_messages
     * @param {number} srvId Service ID
     * @param {CreateMessageStaffRequest} createMessageStaffRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public createMessageStaff(srvId: number, createMessageStaffRequest: CreateMessageStaffRequest, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).createMessageStaff(srvId, createMessageStaffRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get service messages
     * @param {number} srvId Service ID
     * @param {string} dateFrom Start date for messages retrieval
     * @param {string} dateTo End date for messages retrieval
     * @param {string} childId child id string
     * @param {string} userEmail userEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public getMessages(srvId: number, dateFrom: string, dateTo: string, childId: string, userEmail: string, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).getMessages(srvId, dateFrom, dateTo, childId, userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get service messages metadata
     * @param {number} srvId Service ID
     * @param {string} userEmail user Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public getMessagesMetadata(srvId: number, userEmail: string, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).getMessagesMetadata(srvId, userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get service messages metadata for staff_messages
     * @param {number} srvId Service ID
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public getMessagesMetadataStaff(srvId: number, userId: string, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).getMessagesMetadataStaff(srvId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get service messages for staff_messages
     * @param {number} srvId Service ID
     * @param {string} dateFrom Start date for messages retrieval
     * @param {string} dateTo End date for messages retrieval
     * @param {string} userId user id
     * @param {string} [topic] message chatroom or topic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public getMessagesStaff(srvId: number, dateFrom: string, dateTo: string, userId: string, topic?: string, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).getMessagesStaff(srvId, dateFrom, dateTo, userId, topic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets read message status
     * @param {number} srvId Service ID
     * @param {ReadMessageRequest} readMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public readMessage(srvId: number, readMessageRequest: ReadMessageRequest, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).readMessage(srvId, readMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets read message status for staff_messages
     * @param {number} srvId Service ID
     * @param {ReadMessageStaffRequest} readMessageStaffRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public readMessageStaff(srvId: number, readMessageStaffRequest: ReadMessageStaffRequest, options?: AxiosRequestConfig) {
        return CommunicationApiFp(this.configuration).readMessageStaff(srvId, readMessageStaffRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
