import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'
import ImageUI from 'common/components/ui/image-ui'
import Stack from '@mui/material/Stack'
import { useAppContextOrgInfo } from 'common/hooks/use-app-context-org-info'

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '48px',
  height: '48px',
  padding: '4px',
  backgroundColor: theme.palette.common.white,
}))

const CentreLogo = () => {
  const { logoSrc } = useAppContextOrgInfo()

  return (
    <Stack alignItems={'center'} marginBottom={1}>
      {logoSrc && (
        <StyledAvatar>
          <ImageUI src={logoSrc} />
        </StyledAvatar>
      )}
    </Stack>
  )
}

export default CentreLogo
