import { createContext, useState, useContext, ReactNode } from 'react'

type TLabel = string | null | undefined

export type TRoomFilter = {
  id: string
  label: TLabel
}

interface IGlobalFilterContextProps {
  selectedGlobalRoomFilter: TRoomFilter | null | undefined
  setSelectGlobalRoomFilter: (roomId: TRoomFilter | null | undefined) => void
}

interface IGlobalFilterProps {
  children: ReactNode
}

const globalFilterContext = createContext({} as IGlobalFilterContextProps)

export const GlobalFilterContextProvider = (props: IGlobalFilterProps) => {
  const [selectedGlobalRoomFilter, setSelectGlobalRoomFilter] = useState<
    TRoomFilter | null | undefined
  >(null)

  const context = {
    selectedGlobalRoomFilter,
    setSelectGlobalRoomFilter,
  }

  return (
    <globalFilterContext.Provider value={context}>
      {props.children}
    </globalFilterContext.Provider>
  )
}

export const useGlobalFilterContext = () => useContext(globalFilterContext)
