import { createContext, useState, useContext, ReactNode, useEffect } from 'react'
import { startOfDay } from 'date-fns'

interface IDateContextProps {
  selectedDate?: Date
  onSelectDate: (val: Date) => void
}

interface IDateContextProviderProps {
  children: ReactNode
}

const dateContext = createContext({} as IDateContextProps)

export const DateContextProvider = (props: IDateContextProviderProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>()

  useEffect(() => {
    setSelectedDate(startOfDay(new Date()))
  }, [])

  const onSelectDate = (val: Date) => {
    setSelectedDate(startOfDay(val))
  }

  return <dateContext.Provider value={{ selectedDate, onSelectDate }}>{props.children}</dateContext.Provider>
}

export const useDateContext = () => useContext(dateContext)
