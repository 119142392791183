import { useQuery } from '@tanstack/react-query'
import { OrganisationApiFactory } from 'generated/restapi'
import { useApiClient } from 'services/use-api-client'
import { emitError } from 'common/utils/event-emitter'

export type TGetOrganisationInfoVariables = { orgId?: number }

const organisationInfoKeys = {
  'organisationInfo': (params?: TGetOrganisationInfoVariables) => ['fetch-organisation-info', { ...params }],
}

export const useServiceOrganisationInfo = () => {
  const { restConfig, isReady } = useApiClient()

  const FetchOrgInfo = (params: TGetOrganisationInfoVariables) => {
    const { getOrganisation } = OrganisationApiFactory(restConfig)
    const { orgId } = params

    const queryKey = organisationInfoKeys.organisationInfo(params)
    const queryFn = () => getOrganisation(orgId!)

    return useQuery(queryKey, queryFn, {
      enabled: isReady && !!orgId,
      staleTime: 1000 * 60 * 60,
      select: (data) => data.data,
      onError: () => {
        emitError(`Error fetching organisation info.`)
      },
    })
  }

  return { FetchOrgInfo }
}
