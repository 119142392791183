import { useRouter } from 'next/router'
import { memo, useEffect } from 'react'
import { useServiceUserInfo } from 'services/use-service-user-info'

declare global {
  interface Window {
    fcWidget: any
  }
}

const FRESHCHAT_TOKEN = '4ab2f535-c3ad-4ee9-bfec-2f3a34023ab3'
const FRESHCHAT_HOST = 'https://wchat.freshchat.com'
const FRESHCHAT_CUSTOM_WIDGET_CSS = 'custom_fc_frame'

interface IFreshdeskWidgetProps {}
const FreshdeskWidget = (props: IFreshdeskWidgetProps) => {
  const { FetchUserInfo } = useServiceUserInfo()
  const { data: userInfo } = FetchUserInfo()
  const { pathname } = useRouter()

  useEffect(() => {
    if (userInfo) {
      window.fcWidget.user.setFirstName(userInfo.first_name)
      window.fcWidget.user.setLastName(userInfo.last_name)
      window.fcWidget.user.setEmail(userInfo.email)
      window.fcWidget.setExternalId(userInfo.email)
    }
  }, [userInfo])

  useEffect(() => {
    if (pathname === '/' || pathname === '/srv/[srvid]') {
      if (!window.fcWidget.isLoaded()) {
        window.fcWidget.init({
          token: FRESHCHAT_TOKEN,
          host: FRESHCHAT_HOST,
          config: {
            cssNames: {
              widget: FRESHCHAT_CUSTOM_WIDGET_CSS,
            },
          },
        })
      }
    } else {
      window.fcWidget.destroy()
    }
  }, [pathname])

  return <></>
}

export default memo(FreshdeskWidget)
