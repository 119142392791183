import React, {
  useReducer,
  Dispatch,
  createContext,
  ReactNode,
  useContext,
} from 'react'

// context variables
export interface GlobalSearchType {
  id: string
  type: string
  childId: string
  parentId: string
  childName: string
  parentName: string
}

// context variables initial values
const initialState: GlobalSearchType = {
  id: '',
  type: '',
  childId: '',
  parentId: '',
  childName: '',
  parentName: '',
}

// reducer action types
export type AdjustmentAction = {
  type:
    | 'setId'
    | 'setChildId'
    | 'setChildName'
    | 'setParentId'
    | 'setParentName'
    | 'setType'
    | 'setInitialState'
  payload: string
}

// reducer functions
export const reducer = (
  state: GlobalSearchType,
  action: AdjustmentAction
): GlobalSearchType => {
  switch (action.type) {
    case 'setId':
      return { ...state, id: action.payload }
    case 'setChildId':
      return { ...state, childId: action.payload }
    case 'setChildName':
      return { ...state, childName: action.payload }
    case 'setParentId':
      return { ...state, parentId: action.payload }
    case 'setParentName':
      return { ...state, parentName: action.payload }
    case 'setType':
      return { ...state, type: action.payload }
    case 'setInitialState':
      return { ...initialState }

    default:
      break
  }

  return state
}

// to dispatch reducer functions
interface GlobalSearchContextType extends GlobalSearchType {
  dispatch: Dispatch<AdjustmentAction>
}

// to allow GlobalSearchContext to distribute reducer's function and context state variables
export const GlobalSearchContext = createContext<GlobalSearchContextType>(
  initialState as GlobalSearchContextType
)

// GlobalSearchContext wrapper
export const GlobalSearchContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [search, dispatch] = useReducer(reducer, {
    id: '',
    type: '',
    childId: '',
    parentId: '',
    childName: '',
    parentName: '',
  })

  return (
    <GlobalSearchContext.Provider
      value={{
        ...search,
        dispatch,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  )
}

export const useGlobalSearchContext = () => useContext(GlobalSearchContext)
