import { IKContext } from 'imagekitio-react'

interface IImageKitContextProps {
  children: React.ReactNode
}

const ImageKitContext = (props: IImageKitContextProps) => {
  const { children } = props

  return (
    <IKContext urlEndpoint={process.env.NEXT_PUBLIC_IMAGEKIT_URL}>
      {children}
    </IKContext>
  )
}

export default ImageKitContext
