import { AbilityContext } from 'common/casl/components/Can'
import { TSCRole, buildAbilityFor } from 'common/casl/config/ability'
import { useRouter } from 'next/router'
import { ReactNode, useMemo } from 'react'
import { useServiceJobs } from 'services/use-service-jobs'

interface ICaslAbilityContextProps {
  children: ReactNode
}

export const CaslAbilityContextProvider = (props: ICaslAbilityContextProps) => {
  const { FetchJobs } = useServiceJobs()
  const {
    query: { srvid },
  } = useRouter()
  const { data } = FetchJobs()

  const currentUserJob = useMemo(() => {
    return data?.jobs?.find((job) => job?.service_id === parseInt(srvid as string))
  }, [srvid, data?.jobs])

  const ability = buildAbilityFor(currentUserJob?.roles?.at(0) as TSCRole)

  return <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
}
