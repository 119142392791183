import { useQuery } from '@tanstack/react-query'
import { UserApiFactory } from 'generated/restapi'
import { useApiClient } from 'services/use-api-client'
import { emitError } from 'common/utils/event-emitter'

const jobsKeys = {
  'jobs': () => ['fetch-jobs'],
}

export const useServiceJobs = () => {
  const { restConfig, isReady } = useApiClient()

  const FetchJobs = () => {
    const { getJobs } = UserApiFactory(restConfig)

    const queryKey = jobsKeys.jobs()
    const queryFn = () => getJobs()

    return useQuery(queryKey, queryFn, {
      enabled: isReady,
      staleTime: 1000 * 60 * 60,
      select: (data) => data.data,
      onError: () => {
        emitError(`Error fetching jobs.`)
      },
    })
  }

  return { FetchJobs }
}
