import { useQuery } from '@tanstack/react-query'
import { UserApiFactory } from 'generated/restapi'
import { useApiClient } from 'services/use-api-client'
import { emitError } from 'common/utils/event-emitter'

const userInfoKeys = {
  'userInfo': () => ['fetch-user-info'],
}

export const useServiceUserInfo = () => {
  const { restConfig, isReady } = useApiClient()

  const FetchUserInfo = () => {
    const { getUserInfo } = UserApiFactory(restConfig)

    const queryKey = userInfoKeys.userInfo()
    const queryFn = () => getUserInfo()

    return useQuery(queryKey, queryFn, {
      enabled: isReady,
      staleTime: 1000 * 60 * 60,
      select: (data) => data.data,
      onError: () => {
        emitError(`Error fetching user info.`)
      },
    })
  }

  return { FetchUserInfo }
}
