import { CSSProperties, SyntheticEvent, useState } from 'react'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'
import NoImagePlaceholder from 'assets/images/no-image-placeholder.jpg'

interface IImageUIProps {
  src: string
  cover?: boolean
  style?: CSSProperties
}

const ImageUI = (props: IImageUIProps) => {
  const { src, cover, style } = props
  const [loaded, setLoaded] = useState(true)

  return (
    <Grow in={loaded} timeout={800}>
      <Box
        component='img'
        src={src}
        onError={(element: SyntheticEvent<HTMLImageElement>) => {
          element.currentTarget.onerror = null // prevents looping
          element.currentTarget.src = NoImagePlaceholder?.src
        }}
        width='100%'
        height='100%'
        loading='lazy'
        onLoad={() => setLoaded(true)}
        style={{
          objectFit: cover ? 'cover' : 'contain',
          aspectRatio: cover ? '1/1' : 'auto',
          ...style,
        }}
      />
    </Grow>
  )
}

export default ImageUI
