import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability'

type TActions = 'create' | 'read' | 'update' | 'delete'
type TReportStatusActions = 'approve' | 'publish' | 'reject' | 'retract'
type TAvailableActions = 'manage' | TActions | TReportStatusActions

type TReportSubjects = 'Report' | 'Template'
type TActivityTypeSubjects = 'ActivityType'
type TCustomTagSubjects = 'CustomTag'
type TAvailableSubjects = 'all' | TReportSubjects | TCustomTagSubjects | TActivityTypeSubjects

export type TSCRole = 'admin' | 'staff' | 'educator_trainee' | 'educator'

export type TAppAbility = PureAbility<[TAvailableActions, TAvailableSubjects]>

export const AppAbility = PureAbility as AbilityClass<TAppAbility>

export const defineRulesFor = (role: TSCRole) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)

  switch (role) {
    case 'admin':
      can('manage', 'all')
      break

    case 'staff':
      can('manage', 'Report')
      cannot('manage', 'Template')
      cannot('manage', 'CustomTag')
      cannot('manage', 'ActivityType')
      break

    case 'educator':
      can('manage', 'Report')
      cannot('manage', 'Template')
      cannot('manage', 'CustomTag')
      cannot('manage', 'ActivityType')
      break

    case 'educator_trainee':
      can(['create', 'read', 'update', 'delete'], 'Report')
      cannot(['approve', 'publish', 'reject', 'retract'], 'Report')
      cannot('manage', 'Template')
      cannot('manage', 'CustomTag')
      cannot('manage', 'ActivityType')
      break

    default:
      cannot('manage', 'all')
      break
  }

  return rules
}

export const buildAbilityFor = (role: TSCRole) => {
  return new AppAbility(defineRulesFor(role))
}
