import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { format } from 'date-fns'
import { LONG_DATE } from 'common/utils/date-utils'
import CentreLogo from 'modules/home/components/centre-logo'
import CentreName from 'modules/home/components/centre-name'

interface IPrintLayoutProps {
  children: React.ReactNode
}

const PrintLayout = (props: IPrintLayoutProps) => {
  const sxPrintProps = {
    display: 'none',
    '@media print': { display: 'flex' },
  }

  const Header = () => {
    return (
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignContent={'center'}
        width={'100%'}
        sx={sxPrintProps}
        gap={1}
      >
        <CentreLogo />
        <CentreName
          variant='h6'
          sx={{ display: 'flex', alignItems: 'center' }}
        />
      </Stack>
    )
  }

  return (
    <Box sx={{ '@media print': { marginTop: 3, '@page': { marginTop: 0 } } }}>
      <Header />
      {props.children}
    </Box>
  )
}

export default PrintLayout
